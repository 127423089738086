/* /src/components/About/About.css */

.img-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-top: 20px;
}

/* Adjusting image size */
.img-wrap img {
  max-width: 60%;
  height: auto;
}

/* Contact info styling */
.contact-info {
  width: 100%;
  margin-top: 40px;
  text-align: center;
}

.contact-info-phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.contact-info-phone a {
  font-size: 18px;
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.contact-info-phone a:hover {
  text-decoration: underline;
}

.contact-info-phone .fa {
  margin-right: 8px;
}

/* Social icons styling */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 25px; /* Increased spacing between icons */
  margin: 20px 0;
}

.social-icons a {
  color: #434345 !important;
  font-size: 25px;
  text-decoration: none;
}

.social-icons a:hover {
  color: #a73d2d;
}

/* Additional styling for Motto, Creed, Code under logo */
.motto-creed-code {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.motto-creed-code p {
  margin: 5px 0;
  font-size: 16px;
  color: #333;
}

.motto-creed-code .highlight {
  font-weight: bold;
  color: #a73d2d;
}

/* Custom styling for additional headers */
.customH2 {
  font-weight: 700;
  font-size: 20px;
}

/* Commitments of the Pyrate’s Code link styling */
.commitments-link {
  font-size: 18px;
  text-decoration: underline;
}

.commitments-link:hover {
  color: #a73d2d;
}