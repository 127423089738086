/* src/components/HomeSlider/HomeSlider.css */

/* Ensure that the video background covers the entire section */
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensures the video is behind other content */
}

/* Control the content inside the slider */
.fashion-contain {
  position: relative;
  z-index: 2;
  text-align: left; /* Adjust the text alignment as needed */
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  align-items: center;
  height: 100%; /* Ensures full height for proper alignment */
}

/* Custom container for text and buttons */
.fashion-slider .custom-container {
  position: relative;
  z-index: 2;
}

/* Ensure the "Get Started" button is well-aligned */
.fashion-contain .btn-primary {
  display: inline-block;
  margin-top: 20px;
}

/* Arrows styling for the slider */
.slick-prev {
  position: absolute;
  bottom: 10%;
  left: 20px;
  z-index: 3;
  margin-right: 50px; /* Ensure it's not too close to the content */
  margin-top: 150px;
}

.slick-next {
  position: absolute;
  bottom: 10%;
  right: 20px;
  z-index: 3;
  margin-top: 150px;
}

/* Adjust for responsiveness */
@media (max-width: 768px) {
  .fashion-contain .btn-primary {
    margin-top: 10px;
  }

  .slick-prev {
    bottom: 15%;
    left: 15px;
    margin-right: 30px;
  }

  .slick-next {
    bottom: 15%;
    right: 15px;
  }
}

/* Form container styling */
.form-container {
  max-width: 400px; /* Adjust width as needed */
  margin-left: 20px; /* Add left margin for spacing */
  background-color: rgba(255, 255, 255, 0.9); /* Slight background for readability */
  padding: 20px; /* Padding for inner spacing */
  border-radius: 8px; /* Rounded corners for a polished look */
}

/* Form input styling */
.codex-authbox input {
  width: 100%;
  margin-bottom: 10px;
}

/* Logo styling in the form */
.auth-logo img {
  width: 100px; /* Adjust the logo size */
}

