/* /src/components/Tradition/Tradition.css */

.tradition-text{
    font-size: 18px!important;
    color: #434345!important;
}

p.cdxtitle {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
    text-transform: none!important;
    font-weight: 600;
    color: #434345!important;
}

.offset-top-20 {
    margin-top: 20px;
    text-align: center;
    color: #434345!important;
}