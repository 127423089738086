/* src/components/Shop/ProductDetail/UploadTab/UploadTab.css */

#vertical-tab-3 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
}

.upload-container h5 {
    margin-bottom: 15px;
}

.upload-area {
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.upload-area i {
    font-size: 48px;
    margin-bottom: 15px;
}

.upload-area p {
    margin-bottom: 10px;
}

.upload-area button {
    margin-top: 10px;
}

.file-type-info {
    font-size: 14px;
    color: #666;
    border-top: 1px solid #eee;
    padding-top: 15px;
    margin-top: 15px;
}