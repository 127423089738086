/* src/components/Shop/productDetail/TextTab/textTab.css */

/* Add TEXT */
.text-input-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
}

.text-input {
    width: 100%;
    margin-bottom: 10px;
    /* Space between input and button */
}

/* TEXT EDITOR */
.settings-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settings-label {
    margin-right: 10px;
    /* Adjust the spacing as needed */
}

.editing-tools-rating {
    text-align: center;
    padding: 10px;
    /* Adjust padding as needed */
    /* Add other styles as necessary */
}

/* TEXT EDITOR  */

.text-editor-container {
    background: #fff;
    padding: 5px;
    border-radius: 10px;
    /* Adjust the width as necessary */
    margin: auto;
}

.text-editor-container .input-group input.form-control {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
}

.text-editor-container .settings-container {
    border-top: 1px solid #eee;
    padding-top: 15px;
}

.text-editor-container .settings-container>div {
    margin-bottom: 15px;
}

.text-editor-container .settings-container label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
}

.text-editor-container select.form-select,
.text-editor-container input[type=color],
.text-editor-container input[type=range],
.text-editor-container input[type=number] {
    width: 170px !important;
    padding: 4px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
}

.text-editor-container .text-align-settings {
    text-align: center;
}

.text-editor-container .text-align-settings .align-btn {
    background: #f0f0f0;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.text-editor-container .text-align-settings .align-btn:last-child {
    margin-right: 0;
}

.text-editor-container .text-align-settings .align-btn.active {
    background: #007bff;
    color: white;
}

.text-editor-container .submit-container button.btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
}

.text-editor-container .submit-container button.btn-primary:hover {
    background-color: #0056b3;
}

#outline-color-select {
    width: 60px !important;
}

#text-input {
    color: var(--theme-default);
    font-size: 16px;
    font-weight: 600;
}