/* src/layout/Footer/Footer.css */

.cdx-footer {
    background-color: #1a1a1a;
    color: #999;
    text-align: center;
    padding: 40px 0; /* Adjusted padding for increased height */
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  
  .footer-logo {
    height: 50px; /* Increased logo size */
  }
  
  .footer-content p {
    margin: 0;
    color: #999;
    font-size: 14px;
  }
  
  .footer-content a {
    color: #ffd700;
    text-decoration: none;
  }
  
  .footer-content a:hover {
    text-decoration: underline;
  }
  