/* src/components/shop/ProductDetail/ImageEditor/ImageEditor.css */

.editor-wrap {
    /* border: 1px solid green; */
    position: relative;
    width: 100%;
    height: 570px;  /* Height of the container */
    display: flex;
    justify-content: center;  /* Centers the content horizontally */
    align-items: center;  /* Centers the content vertically */
}

.canvas-container {
    /* border: 1px solid red; */
    width: 100% !important;
    height: 100% !important;
    display: block;
    position: relative;
}

.imageEditorCanvas {
    display: block;
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    position: relative;
}
