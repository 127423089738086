/* src/components/Shop/ProductDetail/ShutterstockSearch/ShutterstockSearch.css */

.search-form-container .input-group {
    margin-bottom: 20px;
}

.search-form-container .input-group .form-control {
    border: 1px solid #ddd;
}

.search-form-container .input-group .btn {
    border: 1px solid #ddd;
}

.btn-search-editor {
    background-color: #8A2909;
    color: #fff;
}

.gallery-list {
    display: flex!important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 330px!important;
    overflow-y: scroll;
    padding-top: 20px;
}

.gallery-item {
    flex: 0 1 calc(30.333% - 10px)!important;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-style {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}

.filter-list.cdx-scroll {
    display: grid;
    grid-gap: 10px;
    height: auto;
    overflow-y: auto;
    padding: 5px;
    list-style: none;
}

.filter-list.cdx-scroll li {
    margin-bottom: 10px;
    display: flex;
}

.filter-list.cdx-scroll li a {
    width: 100%;
}

.editor-gallery {
    padding: 10px !important;
}