/* src/components/CustomIndex/CustomIndex.css */

.custom-index-page {
    color: #fff;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    background-color: #191919;
}

.custom-header {
    position: sticky;
    top: 0;
    z-index: 1000; /* Keeps header on top */
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    padding: 20px 0;
    width: 100%;
    display: flex;
    justify-content: flex-start; /* Align content to the left */
}

.logo-container {
    padding-left: 20px; /* Adds space from the left edge */
}

.jolly-roger-logo {
    width: 260px;
    height: auto;
}

/* Remaining CSS stays the same... */

.custom-parallax {
    position: relative;
    background-size: cover;
    background-position: center;
    padding: 100px 0;
    color: #fff;
}

.custom-parallax::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Overlay with 50% opacity */
    z-index: 1; /* Ensures it sits above the background but below content */
}

.parallax-content {
    position: relative;
    z-index: 2; /* Content sits above the overlay */
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.welcome-logo {
    width: 400px;
    margin-bottom: 20px;
}

.divider {
    border: 1px solid #ff0000;
    width: 60%;
    margin: 20px auto;
}

.steps-container p {
    font-size: 18px;
    margin: 20px 0;
}

.steps-container .highlight-red {
    color: red;
    font-size: 20px;
    font-weight: bold;
}

.steps-container .lucky-parrot {
    width: 100px;
    margin: 20px auto;
}

.steps-container strong {
    color: #fff;
}

.note {
    font-size: 16px;
    color: #e0b618;
}

.custom-footer {
    background-color: #000;
    padding: 20px;
    font-size: 14px;
}

.golden {
    color: #e0b618!important;
}

.white {
    color: #fff;
}