/* src/components/HomeSignUp/HomeSignUp.css */

.codex-authbox {
    width: 100%;
    padding: 15px; /* Reduced padding for a tighter layout */
}

.group-form {
    margin-bottom: 10px; /* Less space between groups */
}

.text-center h3 {
    margin: 0;
    color: #333;
    font-size: 20px; /* Smaller font size */
}

.text-center h6 {
    margin: 5px 0; /* Reduce vertical space */
    color: #333;
    font-size: 14px;
}

.group-small {
    display: flex;
    gap: 8px; /* Slightly reduced gap between inputs */
}

.group-small div {
    flex: 1;
}

.form-label {
    font-weight: bold;
    color: #555;
    margin-bottom: 3px; /* Reduced margin below labels */
    font-size: 12px; /* Smaller font size */
}

.form-control {
    width: 100%;
    padding: 6px; /* Reduced padding inside inputs */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 13px; /* Smaller font size */
}

.auth-remember {
    display: flex;
    align-items: center;
    gap: 8px; /* Less space between elements */
    font-size: 12px; /* Smaller font */
}

.custom-check-input {
    position: relative;
}

.custom-input {
    width: 14px; /* Smaller checkbox */
    height: 14px;
}

.button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px; /* Reduced space between buttons */
}

.google-signup {
    display: flex;
    align-items: center;
    gap: 6px;
}

.google-signup span {
    font-size: 13px; /* Smaller font for "or register with" text */
    color: #666;
}

/* Smaller "Sign Up" button */
.btn-primary.btn-sm {
    font-size: 13px;
    padding: 5px 12px; /* Smaller padding */
    background-color: #a73d2d;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}

.btn-primary.btn-sm:hover {
    background-color: #8b3224;
}

/* Styling the Google button */
.google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px; /* Slightly smaller width */
    height: 32px; /* Slightly smaller height */
    background-color: #4285f4;
    color: #fff;
    border-radius: 4px;
    font-size: 16px; /* Smaller icon */
    text-decoration: none;
    transition: background-color 0.2s ease;
}

.google-btn:hover {
    background-color: #357ae8;
}

.google-btn i {
    font-size: 16px; /* Smaller icon */
}
