/* src/components/Shop/Categories/Categories.css */

/* Ensure that the container holding the grid items displays them in a row */
.product-card .categoryslide-8 {
  display: flex;
  flex-wrap: wrap;  /* Allow wrapping if there are too many items */
  gap: 10px; /* Space between items */
}

/* Ensure each grid item has a consistent width */
.product-card .categoryslide-8 .category-grid {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 200px; /* Adjust the width to fit your layout */
  margin-right: 10px; /* Optional spacing between grid items */
}

.product-card .categoryslide-8 .category-grid .img-wrap {
  display: block;
  overflow: hidden;
  text-align: center; /* Center align the image inside */
}

.product-card .categoryslide-8 .category-grid .img-wrap img {
  height: 120px;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

.product-card .categoryslide-8 .category-grid .detail-wrap {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0 10px;
}

.product-card .categoryslide-8 .category-grid .detail-wrap a {
  text-align: center;
}

.product-card .categoryslide-8 .category-grid .detail-wrap h4 {
  font-size: 16px;
  line-height: 1.25;
  height: 100%;
  margin: 0;
  display: -webkit-box; /* Required for webkit browsers */
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

