/* src/components/Shop/ProductDetail/ProductDetail.css */

.form-control {
    text-transform: none !important;
    ;
}

/* Quantity controllers - NOT IN USE  */
.pro-quantity {
    width: 140px;
}

/* Adjust the font size and line height to match the h6 */
.pro-qty {
    font-size: 15px;
    /* Adjust the font size as needed */
    line-height: 1.5;
    /* Adjust the line height to match your design */
    padding: 4px 0 4px 0 !important;
}

/* Style the buttons to match the input height */
.input-group-text {
    padding: .375rem .75rem;
    /* Adjust padding to reduce height */
    font-size: 1rem;
    /* Match font size with the input field */
}

/* Adjust the overall input group size */
.input-group.pro-quantity {
    align-items: center;
    /* Aligns the input and buttons vertically */
}

.quantity-group {
    display: flex;
    align-items: center;
    /* Vertically aligns the label and the input group */
}

.quantity-label {
    margin-right: 10px;
    /* Adds some space between the label and the input group */
    flex-shrink: 0;
    /* Prevents the label from shrinking */
}

.pro-quantity {
    flex-grow: 1;
    /* Allows the input group to grow and fill the space */
}

/*******************
EDITOR START
********************/

/* .product-options {
    border: 1px solid red;
} */

/* TABS */

/* Add your fade transition CSS here */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* .tabs-wrapper {
    border: 1px solid purple;
}
*/
.vertical-tab-content {
    /* border: 1px solid purple; */
    height: 440px;
}

.vertical-tabs-container {
    padding: 10px 0;
    /* border: 1px solid orange; */
}

.vertical-nav-tabs .nav-link {
    color: #333;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: none;
    border-bottom: 1px solid #ddd;
    background-color: transparent;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.vertical-nav-tabs .nav-link:hover {
    background-color: #f8f8f8;
}

.vertical-nav-tabs .nav-link.active {
    background-color: #fff;
    border-right: none;
    font-weight: bold;
    color: var(--theme-default);
}

.vertical-nav-tabs .nav-link i {
    font-size: 1.5em;
    margin-bottom: 5px;
}

.vertical-nav-tabs .nav-link .tab-title {
    font-size: 0.75em;
    display: block;
}

.label-tab-container {
    padding-bottom: 10px;
}

.editor-actions {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
}
