/* src/components/Shop/ProductDetail/CustomizeTab/CustomizeTab.css */

.product-detailright .detail-group .product-color li {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

}

.product-detailright .detail-group .product-color li div {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50px;
    width: 50px;
}