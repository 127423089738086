/* src/layout/Header/Header.css */

.cdx-header {
    position: fixed; /* Use fixed position for all screen sizes */
    top: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    width: 100%; /* Ensures it spans the full width */
    padding: 10px 0; /* Optional padding for better spacing */
  }
  
  /* Media query to add some top padding to the body/content for small screens */
  @media (max-width: 576px) {
    body {
      padding-top: 60px; /* Adjust according to header height */
    }
  }
  